import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';

export default function ImageUploader(props) {
    const inputEl = useRef(null);
    const [file, setFile] = useState();

    const _handleImageChange = (e) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = (event) => {
          props.handleImageLoad(file, event.target.result);
        }
    
        reader.readAsDataURL(file)
    }
    const _triggerFileDialog = () => {
      inputEl.current.click()
    }

    return (
        <>
          <input 
            ref={inputEl}
            className="fileInput d-none" 
            type="file" 
            capture={props.capture}
            onChange={_handleImageChange} />
          <Button 
            block 
            className="mb-2"
            onClick={_triggerFileDialog}>{props.children}</Button>
        </>
    )
}