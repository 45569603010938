import React, { useState } from 'react';
import { Button, CloseButton, Nav, Navbar } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
    

const Item = ({text, link = '/'}) => {
    const history = useHistory();
    return (
        <Nav.Item>
            <Nav.Link onClick={e => history.push(link)}>{text}</Nav.Link>
        </Nav.Item>
    )
}

const Sidebar = ({ children, active = false, onCloseNav }) => {

    const items = [
        {text: 'Item 1', link: '/'},
        {text: 'Item 2', link: '/'},
        {text: 'Item 3', link: '/'},
    ];

    return (
        <aside className={active != true ? 'sidebar shadow' : 'sidebar shadow active'}>
            <div className="sidebar-wrapper">
                <Navbar>
                    <Button onClick={() => onCloseNav()} variant="secondary" className="btn-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </Button>
                </Navbar>
                <Nav className="flex-column flex-nowrap text-truncate">
                    { items.map((item,i) => <Item key={`nav-${i}`} text={item.text} to={item.link}/>) }
                </Nav>
            </div>
        </aside>
    )
}

export default Sidebar