import action_types from '../actionTypes';
import firebase from '../../services/firebase';
import store from '../index';
import { get } from 'lodash';
import { routes } from '../../router';
import { putMessage } from './messages';
import parsePhoneNumber from 'libphonenumber-js'

const LOCAL_STORAGE_TOKEN_KEY = 'toc_auth_token';

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePhone(phone) {
    const re = /(\+?)[1-9]\d{9,15}$/;
    return re.test(phone);
}

function preparePhoneNumber(phone){
    const re = /^\+/;
    // phone = phone.replace(/(\s)|-|\(|\)/g, '');
    // if(!re.test(phone)){
    //     phone = '+' + phone;
    // }
    let formatted = parsePhoneNumber(phone, 'US');
    return get(formatted, 'number', null)
}

export function setLoading(flag) {
    return {
        type: action_types.AUTH_SET_LOADING,
        flag
    }
}

export function setFormError(error) {
    return {
        type: action_types.AUTH_SET_FORM_ERROR,
        error
    }
}

export function setFormField(key, value) {
    return {
        type: action_types.AUTH_SET_FORM_FIELD,
        key,
        value
    }
}

export function setAuthData(data){
    if(data.token){
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(data.token));
    }
    return {
        type: action_types.AUTH_SET_DATA,
        data
    }
}

function setNeedEmail(flag) {
    return {
        type: action_types.AUTH_SET_NEED_EMAIL,
        flag
    }
}

export function clear() {
    return {
        type: action_types.AUTH_CLEAR
    }
}

export function initCurrentUser() {

    return dispatch => {
        dispatch(setLoading(true));
        return firebase.instance.auth().currentUser.getIdToken().then(token => {
            dispatch(setAuthData({ token, is_authenticated: true }))
            dispatch(setLoading(false));
        }).catch(() => {
            dispatch(setLoading(false));
        })
    }
}

export function setEmailSent() {
    return {
        type: action_types.AUTH_SET_EMAIL_SENT
    }
}

export function setCodeSent() {
    return {
        type: action_types.AUTH_SET_CODE_SENT
    }
}

export function login() {
    return dispatch => {

        const login = get(store.getState(), 'auth.form.login', null);
        const login_type = get(store.getState(), 'auth.form.type', null);
        let prepared = null;
        if(login_type == 'email'){
            dispatch(setLoading(true));
            dispatch(_loginWithEmail(login));
        } else if(login_type == 'phone_number' && (prepared = preparePhoneNumber(login))){
            dispatch(setLoading(true));
            dispatch(_loginWithPhoneNumber(prepared));
        } else {
            dispatch(setFormError('Your phone number or email address was not valid'))
            dispatch(putMessage('Your phone number or email address was not valid', 'info', 8000))
        }

    }
}

function _loginWithEmail(email) {

    return dispatch => {

        let action_code_settings = {
            url: `${process.env.REACT_APP_HOST_URL}${routes.email_confirm}`,
            handleCodeInApp: true
        };

        firebase.instance.auth().sendSignInLinkToEmail(email, action_code_settings)
            .then(() => {   
                dispatch(setEmailSent())
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('toc-email-confirmation', email);
                // ...
            })
            .catch((error) => {
                let errorMessage = error.message;
                dispatch(putMessage(errorMessage))
            });
    }
}

function _loginWithPhoneNumber(phone_number) {

    return dispatch => {
        const codeSent = get(store.getState(), 'auth.codeSent', null);
        if(codeSent){
            delete window.recaptchaVerifier;
            firebase.initRecaptcha();
        }
        const appVerifier = window.recaptchaVerifier;

        firebase.instance.auth().signInWithPhoneNumber(phone_number, appVerifier)
            .then((confirmationResult) => {
                dispatch(setCodeSent())
                dispatch(setLoading(false))
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                firebase.removeRecaptcha();
                // ...
            }).catch((error) => {
                console.log('err', error);
                let errorMessage = error.message;
                dispatch(putMessage(errorMessage))
                dispatch(setLoading(false));
            });
    }
}

function _processSuccessSignIn(isNewUser){
    return dispatch => {

        dispatch(setLoading(true));
        dispatch(dispatch(setAuthData({ is_first_login: isNewUser })))
        let promise = null;

        if(isNewUser){
            promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve(dispatch(initCurrentUser()));
                }, 1500);
            })
        } else {
            promise = dispatch(initCurrentUser())
        }
       
        return promise.then(() => {
            dispatch(setLoading(false));
        }).catch(e => {
            console.log('error ', e);
        });
    }
}

// confirm phone auth code
export function confirmCode() {
    return dispatch => {
        if(window.confirmationResult){
            const confirmation_code = get(store.getState(), 'auth.form.confirmation_code', null);
            dispatch(setLoading(true));
            window.confirmationResult.confirm(confirmation_code).then((result) => {
                const isNewUser = get(result, 'additionalUserInfo.isNewUser', null);
                // User signed in successfully.
                return dispatch(_processSuccessSignIn(isNewUser));
     
              }).catch((error) => {
                let errorMessage = error.message;
                if(error.code === 'auth/invalid-verification-code'){
                    dispatch(putMessage('Invalid verification code. Please try again'))
                } else {
                    dispatch(putMessage(errorMessage));
                }
                dispatch(setLoading(false));
                
              });
        }
    }
}

//confirm email via link
export function confirmEmail(confirmationEmail) {
    return dispatch => {
        if (firebase.instance.auth().isSignInWithEmailLink(window.location.href)) {

            let email = confirmationEmail ? confirmationEmail : window.localStorage.getItem('toc-email-confirmation');
      
            if (!email) {
                dispatch(setNeedEmail(true));
                return;
            }
            dispatch(setLoading(true));
            firebase.instance.auth().signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    const isNewUser = get(result, 'additionalUserInfo.isNewUser', null);
                    window.localStorage.removeItem('toc-email-confirmation');
                    return dispatch(_processSuccessSignIn(isNewUser))
                   
                })
                .catch((error) => {
                    let errorMessage = error.message;
                    dispatch(putMessage(errorMessage))
            })
        }
    }
}

export function logout(){
    return dispatch => {
        return firebase.instance.auth().signOut().then(() => {
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
            dispatch(clear());
        })
    }
}

export function checkLoginType() {
    return dispatch => {
        const login = get(store.getState(), 'auth.form.login', null);

        if(validateEmail(login)){
            dispatch(setFormField('type', 'email'));
        } else if(validatePhone(preparePhoneNumber(login))){
            dispatch(setFormField('type', 'phone_number'));
        } else {
            dispatch(setFormField('type', null));
        }
    }
}
