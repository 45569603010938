import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetch as fetchIncomes, setListType, setData as setHistoryData  } from '../../store/actions/payment_history';
import { iam } from '../../store/actions/profile';
import Layout from '../../components/common/Layout';
import { Nav, Container, Col, Row, Button } from 'react-bootstrap'
import PaymentList from '../../components/payment-history/PaymentList';
import { useHistory } from 'react-router';
import { routes } from '../../router';

export default function PaymentHistory() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setHistoryData({
            pagination: {
                limit: 12,
                last_key: null
            },
            data: []
        }))
        dispatch(iam()).then(() => {
            dispatch(fetchIncomes())
        })
    }, [])
    const paymentList = useSelector(state => state.payment_history.data); //@todo remove
   // const paymentList = [{amount: 100, currency: 'USD', paid_at: '2021-01-01'}]; 
    const paymentListType = useSelector(state => state.payment_history.type);
    const isLoading = useSelector(state => state.payment_history.is_loading);
    const next = useSelector(state => state.payment_history.next);

    const format = paymentListType === 'summary' ? 'MMMM' : "MM, DD | hh:mm"

    const toggleType = (type) => {
        dispatch(setListType(type))
        dispatch(setHistoryData({
            pagination: {
                limit: 12,
                last_key: null
            },
            data: []
        }))
        dispatch(fetchIncomes())
    } 
    const handleFetchMore = () => {
        dispatch(fetchIncomes())
    }

    const formattedList = paymentList.map(item => ({
        date: item.paid_at || item.date,
        amount: item.amount,
        currency: item.currency || 'USD',
        key: item.key
    }))


    return (
        <Layout title="My Stats" align="start" handleLeftClick={() => history.push(routes.profile)}>
            <Container>
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        {/* <Nav fill variant="tabs" defaultActiveKey="/home">
                            <Nav.Item>
                                <Nav.Link onClick={() => toggleType('summary')}>Monthly Total</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => toggleType('single')}>History of payments</Nav.Link>
                            </Nav.Item>
                        </Nav> */}
                        <div className="d-flex align-items-center mb-4">
                            <Button 
                                block 
                                className="m-1" 
                                variant={paymentListType === 'summary' ? 'primary' : 'secondary'}
                                onClick={() => toggleType('summary')}
                            >Monthly Total</Button>
                            <Button 
                                block 
                                className="m-1 text-nowrap" 
                                variant={paymentListType !== 'summary' ? 'primary' : 'secondary'}
                                onClick={() => toggleType('single')}
                            >History of payments</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <PaymentList 
                            handleFetchMore={handleFetchMore} 
                            data={formattedList} 
                            hasNext={next}
                            format={format} 
                            isLoading={isLoading}/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}