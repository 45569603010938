import React from 'react';
import logo from '../../assets/images/logo.png';
import {Image} from "react-bootstrap";

export default ({ size = 150}) => {

    return (
        <div className="logo">
            <Image roundedCircle src={logo} width={size} height={size} alt="Toss A Coin"></Image>
        </div>
    )
}