import React from 'react';


function Nav(props){
    return <nav>
        <ul className="navbar-nav">
            {props.children}
        </ul>
    </nav>;
}

function NavItem(props){
    return <div>{props.children}</div>;
}

function Logo(props){
    return <a className="navbar-brand" href="#">&nbsp;</a>;
}

function Header(props) {
    return (
        <header className="navbar navbar-light bg-white">
            {props.children}
        </header>
    )
}

Header.Nav = Nav;
Header.NavItem = NavItem;
Header.Logo = Logo;
export default Header;
