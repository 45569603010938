import { combineReducers } from 'redux';
import profile from './profile';
import auth from './auth';
import messages from './messages';
import payment from './payment';
import user from './user';
import payment_history from './payment_history';

export default combineReducers({
    auth,
    profile,
    messages,
    user,
    payment,
    payment_history
});
