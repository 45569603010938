import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoginType, confirmEmail, setFormField } from '../../store/actions/auth';
import { useHistory } from "react-router-dom";
import { routes } from '../../router';
import VerifyingEmailPlaceholder from '../../components/auth/VerifyingEmailPlaceholder';
import { Form, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import Layout from '../../components/common/Layout';
import Logo from '../../components/Logo';
import { putMessage } from '../../store/actions/messages';

export default function EmailConfirm() {
    const dispatch = useDispatch();
    const history = useHistory();

    const is_authenticated = useSelector(state => state.auth.is_authenticated);
    const login = useSelector(state => state.auth.form.login);
    const loginType = useSelector(state => state.auth.form.type);
    const isLoading = useSelector(state => state.auth.is_loading);
    const needEmail = useSelector(state => state.auth.need_email);
    const is_first_login = useSelector(state => state.auth.is_first_login);

    useEffect(() => {
        
        if(is_authenticated){
            if(is_first_login) {
                history.replace(routes.profile_edit)
            } else {
                history.replace(routes.profile)
            }
        } else {
            setTimeout(() => {
                dispatch(confirmEmail())
            }, 500);
        }
    })

    const handleLoginChange = (v) => {
        dispatch(setFormField('login', v));
        dispatch(checkLoginType())
    }

    const retryConfirm = () => {
        if(loginType == 'email'){
            dispatch(confirmEmail(login))
        } else {
            dispatch(putMessage("Please enter a valid email"))
        }
        
    }

    return (
            <Layout
                align="center"
                hideLeftBtn
            >
                <Container>
                    <Row className="justify-content-center align-items-center" style={{height: '90vh'}}>

                            <Col md="6" >
                                {needEmail ?  
                                <>
                                    <div className="d-flex flex-column align-items-center justify-content-center ">
                                        <Logo size={120} />
                                    </div>

                                    <p>Please enter your email again:</p>
                                    <Form.Group>
                                        <Form.Control 
                                            disabled={isLoading}
                                            onChange={e => handleLoginChange(e.target.value)} 
                                            defaultValue={login}
                                            placeholder="Enter your email or phone number."
                                        ></Form.Control>
                                    </Form.Group>
                                    <Button disabled={isLoading} variant="primary" onClick={retryConfirm} block >{
                                        isLoading ? <Spinner animation="border" size="sm" /> : 'Confirm'
                                    }</Button>
                                </>
                                : <VerifyingEmailPlaceholder />
                                }

                            </Col>
                    </Row>
                </Container>
        </Layout>

    )
}