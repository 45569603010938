import action_types from '../actionTypes';
import { cloneDeep } from 'lodash';

const initial_state = {
    is_loading: false,

    step: 3,
    username: window.localStorage.getItem('tossacoin_payment_user') || null,
    initialAmount: window.localStorage.getItem('tossacoin_payment_initial_amount') || 0,
    extraAmount: window.localStorage.getItem('tossacoin_payment_extra_amount') || 0,
    amount: 0,
    stripe_data: {
        client_secret: null,
        account_id: null
    }
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.PAYMENT_ATTEMPT: {
            let {amount, username, uid} = action.value;
            
            window.localStorage.setItem('tossacoin_payment_user', username) 
            window.localStorage.setItem('tossacoin_payment_initial_amount', amount);
            window.localStorage.setItem('tossacoin_payment_extra_amount', 0);
            return { 
                ...state, 
                initialAmount: amount,
                extraAmount: 0,
                amount: amount,
                uid: uid,
                username: username,
             //   step: 1,
            }
        }
        case action_types.PAYMENT_CONFIRM: {
            return { 
                ...state, 
                step: 2,
            }
        }
        case action_types.PAYMENT_ADD_EXTRA: {
            let {amount} = action.value;
            let total = state.initialAmount + amount;
            window.localStorage.setItem('tossacoin_payment_extra_amount', amount);
            return { 
                ...state, 
                extraAmount: amount,
                amount: total,
            }
        }
        case action_types.PAYMENT_CHECKOUT: {
            return { 
                ...state, 
                step: 3,
            }
        }
        case action_types.PAYMENT_SET_LOADING: {
            return { ...state, is_loading: action.flag }
        }
        case action_types.PAYMENT_SET_FORM_FIELD: {
            let form = cloneDeep(state.form);
            form[action.key] = action.value;
            return { ...state, form}
        }
        case action_types.PAYMENT_SET_STEP: {
            return { ...state, step: action.value }
        }
        case action_types.PAYMENT_SET_STRIPE_DATA: {
            return { ...state, stripe_data: { ...action.data }}
        }
        default:
            return state;
    }
}