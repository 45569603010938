import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Sidebar from './Sidebar';
import SvgIcon from './SvgIcon/SvgIcon';
    

const LeftButton = ({ icon = 'chevron-left', handleClick }) => {
    const history = useHistory();
    
    let handleButtonClick = handleClick ? handleClick : () =>  history.push('/');

    return (
        <div className="navbar-nav-left">
            <Button className="btn-icon" variant="primary" onClick={handleButtonClick}>
                <SvgIcon icon={icon} />
            </Button>
        </div>
    )
}

const Layout = ({ children, title, right, align = 'center', justify = 'center', isSideNav, leftIcon, handleLeftClick, hideLeftBtn, fill = false, hideRight }) => {
    const history = useHistory();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const contentClass = fill ? 'layout__content layout__content-fill' : 'layout__content'
    const className = `d-flex justify-content-${justify} align-items-${align} position-relative ${contentClass}`;

    const handleCloseSidebar = () => {
        setSidebarOpen(false);
    }
    const handleOpenSidebar = () => {
        setSidebarOpen(true);
    }

    return (
        <div className="layout pb-md-5">
            <div className="layout__gradient"></div>
            <header>
                <nav className="navbar navbar-light d-flex justify-content-between">
                    {!hideLeftBtn && <LeftButton icon={leftIcon} handleClick={isSideNav ? handleOpenSidebar : handleLeftClick} />}
                    <div className="navbar-brand m-auto">{title}</div>
                    {!hideRight && <div className="navbar-nav-right">{right}</div>}
                </nav>
            </header>
            <Sidebar active={sidebarOpen} onCloseNav={handleCloseSidebar}></Sidebar>
            <main className={className} className="main">
                <div className="main__content pb-1 pb-md-0">
                {children}
                </div>
                <div className="main__footer"></div>
            </main>
            <div className="layout__footer"></div>
        </div>
    );
}

export default Layout;