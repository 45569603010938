import action_types from '../actionTypes';

const initial_state = {
    is_loading: false,
    uid: null,
    tips: [],
    description: null,
    username: null,
    no_user_found: false,
    qr: null
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.USER_SET_LOADING: {
            return { ...state, is_loading: action.flag }
        }
        case action_types.USER_SET_DATA: {
            return { ...state, ...action.data}
        }
        case action_types.USER_SET_NOT_FOUND: {
            return { ...state, no_user_found: action.flag}
        }
        case action_types.USER_CLEAR: {
            return { ...initial_state, no_user_found: false}
        }
        default:
            return state;
    }
}