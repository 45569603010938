import React, { useEffect, useState } from 'react';
import Layout from '../../components/common/Layout';
import stripeLogo from '../../assets/images/profile/stripe-logo.png';
import iconCheck from '../../assets/images/profile/check-icon.png';
import { Image, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { checkStripeConnect, connectStripeAccount, iam } from '../../store/actions/profile';
import { useHistory } from 'react-router';
import { routes } from '../../router';

export default function StripeManage(){
    const dispatch = useDispatch();
    const history = useHistory();

    const [btnLoading, setBtnLoading] = useState(false);

    const stripeConnected = useSelector(state => state.profile.stripe_connected);
    const isLoading = useSelector(state => state.profile.is_loading);
    const isFirstLogin = useSelector(state => state.auth.is_first_login);

    useEffect(() => {
        dispatch(iam()).then(() => {
            dispatch(checkStripeConnect());
        })
    }, [])

    const handleConnectAccount = () => {
        setBtnLoading(true);
        dispatch(connectStripeAccount()).then(()=>{
            setBtnLoading(false);
        });
    }
    const handleNextClick = () => {
        if(isFirstLogin){
            history.push(routes.profile_qr) 
        } else {
            history.push(routes.profile) 
        }
        
    }

    const title = stripeConnected ? 'Congrats! Stripe successfuly connected.' : 'Connect to Stripe account to get payed'

    return (
        <Layout title="Connect To Stripe" align="center" fill handleLeftClick={() => history.replace(routes.profile)}>
            <Container className="align-self-stretch d-flex flex-column">
                {isLoading 
                ?   <Row className="justify-content-center my-auto">
                        <Spinner animation="border" size="lg" />
                    </Row>
                : <>
                    <Row className="justify-content-center my-auto">
                        <Col md="6" className="text-center">
                            <div className="stripe-logo">
                                <Image src={stripeLogo} />
                                {stripeConnected && <Image src={iconCheck} className="stripe-logo__icon"/> }
                            </div>
                            <h2 className="h3 my-5">{title}</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-auto">
                        <Col md="6" className="text-center">
                            {stripeConnected 
                            ? <Button block onClick={handleNextClick}>Next</Button>
                            : <Button block disabled={btnLoading} onClick={handleConnectAccount}>
                                 {btnLoading ? <Spinner animation="border" size="sm" /> : 'Connect'}
                              </Button>
                            }
                            
                        </Col>
                    </Row>                
                </>
                }
                
            </Container>
        </Layout>
    )
}