import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {checkStripeConnect, connectStripeAccount, iam, setFormField, updateProfile} from '../../store/actions/profile';
import { fetch as fetchIncomes, setData as setHistoryData } from '../../store/actions/payment_history';
// import { Button } from '../../components/common';
import QRGenerator from '../../components/profile/QRGenerator';
import {Button, Container, Row, Col, Image, Alert} from "react-bootstrap";
import Layout from "../../components/common/Layout";
import TipList from '../../components/profile/TipList';
import Info from '../../components/profile/Info';
import IncomeHistory from '../../components/profile/IncomeHistory';
import Settings from '../../components/profile/Settings';
import { logout } from '../../store/actions/auth';

import { useHistory } from "react-router-dom"
import {routes} from '../../router';
import SvgIcon from '../../components/common/SvgIcon';
import Link from "../../components/Link";
import genereateQrUrl from "../../utils/generateQrUrl";

export default function Profile() {
    const dispatch = useDispatch();
    const history = useHistory();

    const stripeConnected = useSelector(state => state.profile.stripe_connected);


    useEffect(() => {
        dispatch(setHistoryData({
            pagination: {
                limit: 5,
                last_key: null
            },
            data: []
        }))
        dispatch(iam()).then(() => {
            dispatch(fetchIncomes({ limit: 5 }))
            dispatch(checkStripeConnect());
        })
    }, [])


    const tips = useSelector(state => state.profile.tips);
    const avatar = useSelector(state => state.profile.avatar);
    const username = useSelector(state => state.profile.username);
    const qr = useSelector(state => state.profile.qr);
    const description = useSelector(state => state.profile.description);
    const isLoading = useSelector(state => state.profile.is_loading);
    // const isLoading = true;
    const payment_list = useSelector(state => state.payment_history.data);  //@todo remove
  //  const payment_list = [{amount: 100, currency: 'USD', paid_at: '2021-01-01'}];

    const handleLogout = () => {
        dispatch(logout());
        history.push('/');
    }

    let user = {
        username,
        avatar,
        description
    }

    const userUrl = genereateQrUrl(qr);

    return (
        <Layout title="My profile" align="center" hideLeftBtn hideRight>
            <Container>
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                       <Info user={user} editable={true} isLoading={isLoading}/>
                        {(!stripeConnected && !isLoading) &&
                            <Alert variant={"warning"}>
                                It`s looks like you have not connected your Stripe account yet.
                                <Link to={{name: 'profile_stripe'}}>Connect account now</Link>
                            </Alert>}
                    </Col>
                </Row>

                <hr />
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <TipList onEditClick={() => history.push(routes.profile_edit)} items={tips} ></TipList>    
                    </Col>
                </Row>
                <hr className="mt-5"/>
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <QRGenerator url={userUrl} placeholder={!username} />   
                    </Col>
                </Row>
                <hr className="mt-5" />
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <IncomeHistory onClick={() => history.push(routes.payment_history)} items={payment_list}></IncomeHistory>
                    </Col>
                </Row>
                <hr className="mt-5" />
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <Settings links={[
                            {
                                title: 'Manage Stripe account',
                                path: { name: 'profile_stripe'},
                                icon: 'stripe'
                            },
                        ]}></Settings>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <div className="pt-5">
                            <Button onClick={e => handleLogout()} block varian="secondary">
                                <span className="icon"><SvgIcon icon="logout" /></span> Log Out
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div>
                    {/* <Button onClick={() => dispatch(connectStripeAccount())}>Connect account to get payed</Button>
            <Button onClick={testUpdate}>Update</Button>
            <QRGenerator></QRGenerator> */}
                    
                </div>
            </Container>
        </Layout>
    )
}