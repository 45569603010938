import action_types from '../actionTypes';
import { cloneDeep } from 'lodash';
const initial_state = {
    is_loading: false,
    uid: null,
    tips: [],
    description: null,
    username: null,
    stripe_connected: false,
    qr: null,
    form: {
        tips: [],
        description: '',
        username: '',
        avatar: null
    },

}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.PROFILE_SET_LOADING: {
            return { ...state, is_loading: action.flag }
        }
        case action_types.PROFILE_SET_DATA: {
            return { ...state, ...action.data, form: {
                tips: action.data.tips,
                username: action.data.username,
                description: action.data.description,
                qr: action.data.qr,
                avatar: action.data.avatar

            }}
        }
        case action_types.PROFILE_SET_FORM_FIELD: {
            let form = cloneDeep(state.form);
            form[action.key] = action.value;
            return { ...state, form}
        }
        case action_types.PROFILE_SET_STRIPE_ACCOUNT_STATE: {
            return { ...state, stripe_connected: action.flag}
        }
        default:
            return state;
    }
}