import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoginType, setFormField, login as loginUser, confirmCode, setFormError } from '../../../store/actions/auth';
import auth from '../../../store/reducers/auth';
import { Input } from '../../common';
import {Button, Form, Image, Spinner} from "react-bootstrap";
import ConfirmationCodeInput from '../ConfirmationCodeInput';
import Logo from '../../Logo';

const Header = ({dense = false, login = null, msg = null, submsg}) => {
    let size = dense == false ? 180 : 80;
    return (
        <div className="d-flex flex-column align-items-center justify-content-center mb-4">
            <div className="auth-form__logo">
                <Logo size={size} />
            </div>
            {msg != null && <div className="mt-3">{msg}</div>}
            {login != null && <div><strong>{login}</strong></div>}
            {submsg && <div className="mt-3">{submsg}</div>}
        </div>
    )
}

export default function AuthForm(props) {
    const dispatch = useDispatch();

    const login = useSelector(state => state.auth.form.login);
    const confirmation_code = useSelector(state => state.auth.form.confirmation_code);
    const emailSent = useSelector(state => state.auth.emailSent);
    const codeSent = useSelector(state => state.auth.codeSent);
    const isLoading = useSelector(state => state.auth.is_loading);
    const formError = useSelector(state => state.auth.form_error);


    const handleLoginChange = (v) => {
        dispatch(setFormField('login', v));
        dispatch(checkLoginType())
        if(formError){
            dispatch(setFormError(null))
        }
    }
    const hadleConfirmationCodeInput = (v) => {
        dispatch(setFormField('confirmation_code', v));
    }
    const handleCodeSubmit = () => {
        dispatch(confirmCode())
    }
    const handleSubmit = e => {
        e.preventDefault();
        if(codeSent){
            handleCodeSubmit();
        } else {
            dispatch(loginUser());
        }
       
    }

    const loginDisabled = emailSent || codeSent || isLoading;
    return (
        <Form className="auth-form" onSubmit={handleSubmit}>
            <Header
                // dense={emailSent||codeSent}
                msg={
                    emailSent ? "We've sent you an email to:" : (codeSent ? "We've sent you an SMS with the code to:" : null)
                }
                submsg={
                    emailSent ? 'Please check your email and follow the instruction' : null
                }
                login={
                    (emailSent||codeSent) ? login : null
                }
                ></Header>
            {!(emailSent||codeSent) && (
                <>
                    <h1 className="text-center mb-4">Welcome to Toss a coin!</h1>
                    <p>The easiest way to get payed on the web. Just sign up below, setup your profile, link your Stripe account, print your coin code, and start receiving $$$. Anyone can toss you a coin using Apple Pay or Google Pay without creating an account or entering a card number in only a few seconds!</p>
                    <hr className="my-4"/>
                    <p>Please enter your mail or phone number to proceed:</p>
                    <Form.Group>
                        <Form.Control 
                        disabled={emailSent}
                        onChange={e => handleLoginChange(e.target.value)} 
                        isInvalid={!!formError}
                        defaultValue={login}
                        placeholder="Enter your email or phone number."
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                                {formError}
                        </Form.Control.Feedback>
                    </Form.Group>
                </>
            )}
            {codeSent && (
                <ConfirmationCodeInput 
                    submit={handleCodeSubmit} 
                    handleInput={hadleConfirmationCodeInput}
                    value={confirmation_code}
                    isLoading={isLoading}
                    resendCode={() => dispatch(loginUser())}
                ></ConfirmationCodeInput>
            )}
            
            {!(emailSent||codeSent) && (
                <Button disabled={loginDisabled} variant="primary" block onClick={e => dispatch(loginUser())}>{
                    isLoading ? <Spinner animation="border" size="sm" /> : 'Login'
                }</Button>
            )}
        </Form>
    )
}