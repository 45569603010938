import React from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

function ListItem({ currency, amount, date, format}){
    let formatted = moment(date).format(format)

    const currencySign = {
        'USD': '$',
        'EUR': '€'
    }

    return (
        <ListGroup.Item className="d-flex align-items-center justify-content-between payment-item my-1" bsPrefix="payment" style={{marginBottom: 5}}>
            <span>{formatted}</span>
            <span className="payment-item__amount">{ currencySign[currency] || currency }<b>{amount}</b></span>
        </ListGroup.Item>
    )
}

function Empty(){
    return <div className="text-center mx-auto my-3">You haven't received any tips yet.</div>
}

export default ({ data = [], format, isLoading, handleFetchMore, hasNext }) => {
    return (
        <ListGroup variant="flush">
            <InfiniteScroll
                dataLength={data.length} //This is important field to render the next data
                next={handleFetchMore} 
                hasMore={hasNext}
                loader={<div className="my-2">
                            <Spinner size="sm" animation="border" />
                        </div>}
                >
                    {
                        data.length > 0 
                        ? data.map((item) => ( <ListItem key={item.key} {...item} format={format} />)) 
                        : <Empty />
                    }
                </InfiniteScroll>
            {/* {
                data.length > 0 
                ? data.map((item) => ( <ListItem key={item.key} {...item} format={format} />)) 
                : <Empty />
            } */}
            {/* {isLoading && (
                <div className="my-2">
                    <Spinner size="sm" animation="border" />
                </div>
            )} */}
        </ListGroup>
    );
}