import { get } from 'lodash';
import store from '../../store';
import { logout } from '../../store/actions/auth';
import { putMessage } from '../../store/actions/messages';

export default {
  type: 'response',
  error: error => {
    let errors = get(error, 'response.data.errors', []);
    let status = get(error, 'response.status', null);



    let token = get(store.getState(), 'auth.token', null)
    if(status == 403 && token){
      store.dispatch(putMessage('Your session has timed out. Please sign in again'));
      store.dispatch(logout());
      return Promise.reject(error);
    }
    if(status == 500){
      store.dispatch(putMessage('The server returned an error, please try again in a few minutes'));
      return Promise.reject(error);
    }
    
    errors.forEach(item => {
      store.dispatch(putMessage(item.detail))
    })


    return Promise.reject(error);
  }
};
