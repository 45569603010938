import action_types from '../actionTypes';
import store from '../index';
import { get } from 'lodash';
import api from '../../api';
import { putMessage } from './messages';
import firebase from '../../services/firebase';

export function setLoading(flag) {
    return {
        type: action_types.PROFILE_SET_LOADING,
        flag
    }
}

export function setData(data) {
    return {
        type: action_types.PROFILE_SET_DATA,
        data
    }
}

export function setFormField(key, value) {
    return {
        type: action_types.PROFILE_SET_FORM_FIELD,
        key,
        value
    }
}

export function setStripeAccountState(flag){
    return {
        type: action_types.PROFILE_SET_STRIPE_ACCOUNT_STATE,
        flag
    }
}

export function iam() {
    
    return dispatch => {
        const token = get(store.getState(), 'auth.token', null);
        if(!token) return; 
        
        dispatch(setLoading(true));
        return api.user.iam().then((result) => {
            dispatch(setData(result.data));
            dispatch(setLoading(false));
        }).catch(error=>{
            console.error(error);
            dispatch(setLoading(false));
        })
    }
}

export function updateProfile() {
    return dispatch => {
        dispatch(setLoading(true));

        const form = get(store.getState(), 'profile.form', {});
        
        return api.user.update(form).then(res => {

            dispatch(setData(res.data));
            dispatch(setLoading(false));
            dispatch(putMessage('Profile successfully updated'));
            if(res.status === 200){
                return { status: 'success' }
            }
        }).catch(e => {

            dispatch(setLoading(false));
        })
    }
}

export function connectStripeAccount(){
    return dispatch =>  {
        
        const refresh_url = process.env.REACT_APP_HOST_URL + '/profile/stripe';
        const return_url = process.env.REACT_APP_HOST_URL + '/profile/stripe';

        return api.account.store({
            "refresh_url": refresh_url, 
            "return_url": return_url
        }).then((result) => {
            
            document.location.href = result.data.url;
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}

export function uploadAvatar(avatar, dataUrl){
    return dispatch => {
        dispatch(setFormField('avatar', dataUrl))
       
        const userUid = get(store.getState(), 'profile.uid', null);
        let ref = `${userUid}/` + avatar.name;
        firebase.storageUsersUpload(ref, avatar).catch(error => {
            dispatch(putMessage(error.message))
        })
    }
}

export function checkStripeConnect(){
    return dispatch => {
        const uid = get(store.getState(), 'profile.uid', null);

        dispatch(setLoading(true));

        return api.account.fetch({user_uid: uid}).then(({ data }) => {
            const flag = data.onboarding && data.onboarding === true;
            dispatch(setStripeAccountState(flag))
        }).catch(error => {
            //dispatch(putMessage(error))
            console.log('error', error)
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
}