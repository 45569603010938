export default {
    FORM_FIELD_INPUT: 'FORM_FIELD_INPUT',

    //AUTH
    AUTH_SET_LOADING: 'AUTH_SET_LOADING',
    AUTH_SET_DATA: 'AUTH_SET_DATA',
    AUTH_CLEAR: 'AUTH_CLEAR',
    AUTH_SET_FORM_FIELD: 'AUTH_SET_FORM_FIELD',
    AUTH_SET_EMAIL_SENT: 'AUTH_SET_EMAIL_SENT',
    AUTH_SET_CODE_SENT: 'AUTH_SET_CODE_SENT',
    AUTH_SET_NEED_EMAIL: 'AUTH_SET_NEED_EMAIL',
    AUTH_SET_FORM_ERROR: 'AUTH_SET_FORM_ERROR',

    //PROFILE
    PROFILE_SET_LOADING: 'PROFILE_SET_LOADING',
    PROFILE_SET_DATA: 'PROFILE_SET_DATA',
    PROFILE_SET_FORM_FIELD: 'PROFILE_SET_FORM_FIELD',
    PROFILE_SET_STRIPE_ACCOUNT_STATE: 'PROFILE_SET_STRIPE_ACCOUNT_STATE', 

    //PAYMENT
    PAYMENT_SET_LOADING: 'PAYMENT_SET_LOADING',
    PAYMENT_SET_FORM_FIELD: 'PAYMENT_SET_FORM_FIELD',
    PAYMENT_SET_STEP: 'PAYMENT_SET_STEP',
    PAYMENT_ATTEMPT: 'PAYMENT_ATTEMPT',
    PAYMENT_CONFIRM: 'PAYMENT_CONFIRM',
    PAYMENT_ADD_EXTRA: 'PAYMENT_ADD_EXTRA',
    PAYMENT_CHECKOUT: 'PAYMENT_CHECKOUT',
    PAYMENT_SET_STRIPE_DATA: 'PAYMENT_SET_STRIPE_DATA',

    //USER
    USER_SET_LOADING: 'USER_SET_LOADING',
    USER_SET_DATA: 'USER_SET_DATA',
    USER_SET_NOT_FOUND: 'USER_SET_NOT_FOUND',
    USER_CLEAR: 'USER_CLEAR',

    //MESSAGE
    MESSAGE_PUT_DATA: 'MESSAGE_SET_DATA',
    MESSAGE_CLEAR: 'MESSAGE_CLEAR',
    MESSAGE_REMOVE: 'MESSAGE_REMOVE',

    //PAYMENT HISTORY
    PAYMENT_HISTORY_SET_LOADING: 'PAYMENT_HISTORY_SET_LOADING',
    PAYMENT_HISTORY_SET_DATA: 'PAYMENT_HISTORY_SET_DATA',
    PAYMENT_HISTORY_SET_TYPE: 'PAYMENT_HISTORY_SET_TYPE'

}