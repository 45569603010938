import action_types from '../actionTypes';
import { cloneDeep, remove } from 'lodash';

const initial_state = {
    messages: []
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.MESSAGE_PUT_DATA: {
            let messages = cloneDeep(state.messages);
            messages.push(action.message);
            return { ...state, messages }
        }
        case action_types.MESSAGE_REMOVE: {
            let messages = cloneDeep(state.messages);
            remove(messages, (item) => item.id == action.id);
            return { ...state, messages }
        }
        default:
            return state;
    }
}