import routes from './routes';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import Profile from '../pages/Profile';
import Tip from '../pages/Tip';
import NotFound from '../pages/NotFound';
import Payment from '../pages/Payment';
import PaymentHistory from '../pages/PaymentHistory';
import EmailConfirm from '../pages/EmailConfirm';
import ProfileEdit from '../pages/ProfileEdit';
import StripeManage from '../pages/StripeManage';
import GenerateQR from '../pages/GenerateQR';

export default [
    {
        component: Home,
        path: routes.home,
        exact: true,
        children: [

        ]
    },
    {
        component: SignIn,
        path: routes.signin
    },
    {
        component: ProfileEdit,
        path: routes.profile_edit,
        auth: true
    },
    {
        component: StripeManage,
        path: routes.profile_stripe,  
        auth: true
    },
    {
        component: GenerateQR,
        path: routes.profile_qr,  
        auth: true
    },
    {
        component: Profile,
        path: routes.profile,
        auth: true
    },

    {
        component: Payment,
        path: routes.payment,  
    },
    {
        component: PaymentHistory,
        path: routes.payment_history,  
    },
    {
        component: EmailConfirm,
        path: routes.email_confirm,  
    },

    // Always the last one!
    {
        component: Tip,
        path: routes.tip,
    },

    {
        component: NotFound,
        path: '*'
    }
]