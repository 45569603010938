import React from 'react';
import Layout from '../../components/common/Layout';
import { Container, Row, Col } from 'react-bootstrap';

export default function NotFound(){

    return (
        <Layout fill alig="center">
            <Container className="align-self-stretch d-flex flex-column generate-qr ">
                <Row className="justify-content-center my-auto ">
                    <Col md="12" className="text-center px-5">
                        <h1>404</h1>
                    </Col>
                    <Col md="12" className="text-center px-5">
                        <h2>Page Not Found</h2>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}