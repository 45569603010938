import React, { useEffect, useState } from 'react';
import StripePayment from '../../components/payment/StripePayment';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../router';
import { pay, setStep, extra, confirm, checkout } from '../../store/actions/payment';
import Layout from '../../components/common/Layout';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Info from '../../components/profile/Info';
import { putMessage } from '../../store/actions/messages';


const ExtraCharge = ({onSelect, selected = 0, summary}) => {
    const extra = [0,100,500,1000,2000];
    return (
        <div>
                <div className="bg-light p-4">{summary}$</div>
                <div>Would you like to add an extra:</div>
                <ul className="list-unstyled">
                    {extra.map((amount) => (
                        <li key={amount} className="mb-1">
                            <Button variant="secondary" block
                            active={selected == amount} 
                            onClick={() => onSelect(amount)}>${(amount/100).toFixed(2)}</Button>
                        </li>
                    ))}
                </ul>
        </div>
    )
}


const StepOne = ({user, amount}) => {
    const dispatch = useDispatch();
    const onSubmit = () => dispatch(confirm({}))
    return (
        <>
            <Info user={user} editable={false}/>
            <Button onClick={onSubmit} variant="primary" block>Pay ${(amount / 100).toFixed(2)}</Button>
        </>
    )
}


const StepTwo = ({user, amount}) => {
    const extraAmount = useSelector(state => state.payment.extraAmount);
    const dispatch = useDispatch();
    const onSelect = (add) => dispatch(extra({
        amount: add
    }))
    const onSubmit = () => dispatch(pay())
    const summary = (amount / 100).toFixed(2);
    return (
        <>
            <Info dense details={false} user={user} editable={false}/>
            <ExtraCharge onSelect={onSelect} selected={extraAmount} summary={summary}></ExtraCharge>    
            <Button onClick={onSubmit} variant="primary" block>Pay ${summary}</Button>
        </>
    )
}

const StepThree = ({user, amount, stripeData, handleSuccess, handleError}) => {

    const [useCard, setUseCard] = useState(false);

    return (
        <>
            <hr className="my-4"/>
            <Info  details={false}  user={user} editable={false}/>
            <hr className="my-4"/>
            <p>Select method: </p>
            <StripePayment handleError={handleError} handleSuccess={handleSuccess} accountId={stripeData.account_id} secret={stripeData.client_secret} useCard={useCard}></StripePayment>
            {!useCard && <Button className="mt-auto" variant="info" block onClick={() => setUseCard(true)}>Credit Card</Button>}
        </>
    )
}

const StepFour = ({ handleRedirect }) => { 
    return (
        <>
            <h2 className="m-auto " style={
                {
                    fontSize: '2.3rem'
                }
            }> 
                Thanks!
            </h2>
            <Button block className="mt-auto" onClick={handleRedirect}>Ok</Button>
        </>
    )
}


export default function Payment(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const status = urlParams.get('status');

    const amount = useSelector(state => state.payment.amount);
    const intialAmount = useSelector(state => state.payment.intialAmount);
    const step = useSelector(state => state.payment.step);
    const usernameFetched = useSelector(state => state.user.username);
    const avatar = useSelector(state => state.user.avatar);
    const description = useSelector(state => state.user.description);
    const stripeData = useSelector(state => state.payment.stripe_data);

    let user = {
        username: usernameFetched,
        avatar,
        description
    }

    useEffect(() => {
        // if(!amount && !user.username){
            
        //     history.replace(routes.home)
        // }
        if(status === 'success'){
            goTo(4);
        }
    }, [amount, user, status]);

    const goTo = (v) => {
         dispatch(setStep(v))
    };
    const handleRedirect = () => {
        history.push(routes.home);
    }
    const handleSuccessPayment = () => {
        goTo(4);
    }
    const handleErrorPayment = (error) => {
        dispatch(putMessage(error))
    }

    return (
        <Layout
            title={"Toss a coin to:"}
            align="center"
            fill
            handleLeftClick={() => {
                step > 1 ? goTo(step - 1) : history.goBack()
            }}>
            <Container className="align-self-stretch d-flex flex-column">
                <Row className="justify-content-center h-100">
                    <Col md="6" className="text-center d-flex flex-column">
                        {/* {step == 1 && <StepOne user={user} amount={amount}></StepOne>}
                        {step == 2 && <StepTwo user={user} amount={amount}></StepTwo>} */}
                        {/* {step == 3 && <StepThree user={user} handleSuccess={handleSuccessPayment} handleError={handleErrorPayment} amount={amount} stripeData={stripeData}></StepThree>} */}
                        {/* <pre>{JSON.stringify(info, null, 2)}</pre> */}
                        {step == 4 && <StepFour handleRedirect={handleRedirect}></StepFour>}
                    </Col>
                </Row>
               
            </Container>
        </Layout>
    )
}