import React from 'react';
import { Nav } from 'react-bootstrap';
import SectionHeader from '../SectionHeader';
import Link from '../Link';

const NavLink = ({title, path = '/', onClick = null, icon}) => {

    const icons = {
        'stripe': 'settings__icon-stripe'
    }

    let iconClass = 'settings__icon ';
    if(icons[icon]){
        iconClass += icons[icon];
    }

    return (
        <Nav.Item className="flex-1 settings__item">
             <Link to={path}><div className={iconClass}></div>{title}</Link>
        </Nav.Item>
    )
}

const Settings = ({links}) => {
    return (
        <>
            <SectionHeader
                title="Settings" />
            <Nav className="d-flex flex-column settings">
                { links.map((link,i) => <NavLink key={i} {...link}></NavLink>) }
            </Nav>
        </>
    )
}

export default Settings