import React, { useState, useEffect } from 'react';
// import { Input, Button } from '../../common';
import {Form, Button, Spinner} from "react-bootstrap";

const CODE_VALUE_LIMIT = 7;

function isNumeric(str){
    return /^\d+$/.test(str);
}

const Timer = (props) => {
    //const [count, setCount] = useState(59);
    const { count, setCount, handleTimerEnd} = props;
    useEffect(() => {
        const timer = setInterval(() => {
            if(count == 0){
                handleTimerEnd();
                clearInterval(timer);
                setCount(0);
            } else {
                setCount(count - 1);
            }
            
        }, 1000);
        return () => clearInterval(timer);
    }, [count])

    return (
        <span className="timer">
            {count}
        </span>
    )
}

export default function ConfirmationCodeInput(props) {
    const [count, setCount] = useState(59);
    const [resendEnabled, setResendEnabled] = useState(false)

    const handleCodeInput = (v) => {
        if(v.length == 0 || (v.length < CODE_VALUE_LIMIT && isNumeric(v))){
            props.handleInput(v);
        }
    }
    const enableResend = () => {
        setResendEnabled(true);
    }
    const resendCode = () => {
        props.resendCode();
        setCount(59);
        setResendEnabled(false);
    }
    const submitDisabled = props.isLoading || props.value.length === 0;

    return (
        <>
            <Form.Group>
                <Form.Control value={props.value} className="text-center" type="password" onChange={e => handleCodeInput(e.target.value)}></Form.Control>
            </Form.Group>
            <p className="text-center">
                Didn`t got message? 
            </p>
            <div className="d-flex justify-content-center align-items-center flex-column py-3">
                <Timer count={count} setCount={setCount} handleTimerEnd={enableResend} />
                <Button disabled={!resendEnabled} onClick={resendCode} variant="link">Resend Code</Button>
            </div>
            <Button block variant="primary" onClick={props.submit} disabled={submitDisabled}>{
                props.isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'
            }</Button>
        </>
    );
}