import React from 'react';
import { Toast } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

export default function Toats() {

    const messages = useSelector(state => state.messages.messages);

    return (
        <div
        style={{
            position: 'fixed',
            top: 5,
            right: 5,
            zIndex: 2000
        }}>
            {messages.map(msg => (
                <Toast variant={msg.variant} key={msg.id}>
                    <Toast.Body><small>{msg ? msg.body : null}</small></Toast.Body>
                </Toast>
            ))}
        </div>
    )
}