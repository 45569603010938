import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import routes from './routes-config';
import routes_names from './routes';
import { useSelector } from 'react-redux';

export default function DefaultRouter() {
    return (
    <Router>
        <Switch>
            {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
            ))}
        </Switch>
      </Router>
    )
}


// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
    const is_authenticated = useSelector(state => state.auth.is_authenticated);
    const is_redirect = route.auth === true && is_authenticated === false;
    //const is_redirect = false;
    return (
      <Route
        path={route.path}
        exact={!!route.exact}
        render={props => (
          // pass the sub-routes down to keep nesting
          is_redirect ? 
            <Redirect
              to={routes_names.signin}
            /> : 
            <route.component {...props} routes={route.children} />
        )}
      />
    );
  }