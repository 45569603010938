import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../router';
import _ from 'lodash';

export default function Link(props) {
    const { to } = props;
    let path = routes[to.name];

    if(to.params){
        _.each(to.params, (v, k) => {
            path = path.replace(`:${k}`, v);
        });
    }

    return (
        <RouterLink to={path}>
            {props.children}
        </RouterLink>
    )
}