import React from 'react';
import moment from 'moment';
import {Button} from 'react-bootstrap';
import SectionHeader from '../SectionHeader';

const Empty = () => (
    <li>
        <div className="payment-item bg-secondary">
            <div className="text-center mx-auto">You haven't received any tips yet.</div>
        </div>
    </li>
)

const ListItem = ({amount, currency, paid_at, subject}) => {
    const date = paid_at ? moment(paid_at).format('MM/DD YY') : '-';
    const formatted = amount ? (amount/100).toFixed(2).toString() : '0.00';

    const currencySign = {
        'USD': '$',
        'EUR': '€'
    }

    return (
        <li className="mb-1">
            <div className="payment-item">
                <div>{date}</div>
                <div className="payment-item__amount">{ currencySign[currency] || currency }<b>{formatted}</b></div>
            </div>
        </li>
    )
}

const IncomeHistory = ({ items, onClick }) => {
    return (
        <>
            <SectionHeader 
                // isBtn={onClick}
                // onBtnClick={onClick}
                icon="chevron-right"
                title="Payment History" />
            <ul className="list-unstyled">
                {
                    items.length > 0 
                    ? items.map((item) => <ListItem key={item.toString()} {...item}></ListItem>) 
                    : <Empty></Empty>
                }
            </ul>    
        </>
    );
}

export default IncomeHistory