import React from 'react';
import SvgIcon from '../common/SvgIcon';
import {Button} from "react-bootstrap";

export default ({ title, icon, onBtnClick, isBtn}) => {
    return (
        <div className="d-flex justify-content-center align-items-center py-1 section-header">
            <h2 className="section-header__title">{title}</h2>
            {isBtn && 
                <Button variant="primary" className="btn-icon btn-sm ml-auto section-header__btn" onClick={onBtnClick}>
                    <SvgIcon icon={icon} />
                </Button>
            }
        </div>
    )
}