export default {
    home: '/',
    signin: '/login',
    profile: '/profile',
    profile_edit: '/profile/edit',
    profile_stripe: '/profile/stripe',
    profile_qr: '/profile/qr',
    tip: '/qr/:qr',
    payment: '/payment',
    payment_history: '/payment-history',
    email_confirm: '/email-confirm'
}