import Router, { routes } from '../router/index.js';
import Header from './Header';
import Toasts from './Toasts';

function App() {
    return (
        <>
            {/* <Header>
                <Header.Logo />
                <Header.Nav>
                    <Header.NavItem>
                        Nav
                    </Header.NavItem>
                </Header.Nav>
            </Header> */}
            <Router />
            <Toasts />
        </>
    );
}

export default App;
