import React from 'react';
import {Image, Button, Spinner} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import torchImage from '../../assets/images/torch.png';

const Avatar = ({user, editable = false, onClick = null, dense = false, isLoading}) => {
    const history = useHistory();
    const edit = () => history.push("/profile/edit");
    const username = user.username ? user.username : 'Anonymous';
    const unp = encodeURIComponent(username);
    const url = user.avatar ? user.avatar : `https://eu.ui-avatars.com/api/?name=${unp}&background=e2e8f0&color=ffffff&size=180`;
    const imageSize = dense == false ? 160 : 80;
    return (
        <div className="d-flex justify-content-center align-items-center">
            <Image src={torchImage}  />
            <div className="user-avatar mx-1">
                <Image className="user-avatar-image" width={imageSize} height={imageSize} roundedCircle src={url} alt={username}/>
                {isLoading && 
                    <div className="user-avatar-loading" >
                        <Spinner animation="border"  />
                    </div>
                }
                {
                    editable && (
                        <>
                            {(onClick == null) && 
                            <Button className="btn-icon user-action" variant="primary" onClick={edit}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                            </Button>}
                            {(onClick != null) && 
                            <Button className="btn-icon user-action" variant="primary" onClick={onClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            </Button>}
                        </>
                    )
                }
            </div>
            <Image src={torchImage}  />
        </div>
    )
}


const Name = ({user, isLoading}) => {
    const username = user.username ? user.username : 'Anonymous';
    if(isLoading) return <Spinner animation="border" size="sm" />;
    return <h1 className="user-name">{ username }</h1>
}


const Description = ({user}) => {
    const description = user.description ? user.description : 'No information about the user so far.';
    return <p className="mt-3 user-description">{description ? description : 'No information about the user so far.'}</p>
}

const Info = ({user, details = true, editable = false, dense = false, onImageEditClick, hideName, isLoading }) => {
    return (
        <div className="user-info">
            <Avatar user={user} editable={editable} dense={dense} onClick={onImageEditClick} isLoading={isLoading}/>
            {!hideName && <Name user={user} isLoading={isLoading}/>}
            {details == true && !isLoading && <Description user={user}/>}
        </div>
    )
}

export default Info