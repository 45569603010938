import React from 'react';
import {Button, Spinner} from "react-bootstrap";
import SectionHeader from '../../SectionHeader';

const ListItem = ({children, onClick, selected}) => {

    const btnClassName = `btn-bordered ${selected ? 'btn-selected' : ''}`;

    return (
        <li className="mb-2">
            <Button onClick={onClick} className="mt-1" variant="primary" block className={btnClassName}>
                {selected && <span className="icon">•</span>}
                ${children}
            </Button>
        </li>
    )
}

export default function TipSelector(props) {
    const tips = [...props.tips];

    return (
        <section>
            <SectionHeader
                title="Tip Amounts"/>
            {props.isLoading ? <Spinner animation="border"/> : <ul className="list-unstyled px-5 px-md-0">
                {tips.map((tip, index) => <ListItem onClick={() => props.handleTipClick(tip)}
                                                    selected={props.selectedAmount === tip}
                                                    key={index}>{parseInt(tip).toFixed(2)}</ListItem>)}
            </ul>}

        </section>
    )
}