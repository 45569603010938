import React, { useState } from 'react';
import {Button} from "react-bootstrap";
import SvgIcon from '../../common/SvgIcon/SvgIcon';
import SectionHeader from '../../SectionHeader';

const Empty = () => (
    <li>
        <div className="payment-item bg-secondary">
            <div className="text-center mx-auto">You haven't set up this section yet.</div>
        </div>
    </li>
)

const ListItem = ({amount, secondary, editable, onDelete}) => {

    const [isEdit, setIsEdit] = useState(false);

    const enableEdit = () => {
        if(editable){
            setIsEdit(true)
        }
    };
    const disableEdit = () => {
        if(editable){
            setIsEdit(false)
        }
    };
    const handleDeleteClick = (e) => {
        e.stopPropagation();
        if(onDelete){
            onDelete(amount)
        }
    }   
    const formattedAmount = parseFloat(amount).toFixed(2);
    return (
        <li className="mb-2" onMouseEnter={enableEdit} onMouseLeave={disableEdit}>
            {!isEdit 
                ? <Button className="mt-1" variant={secondary ? 'secondary' : 'primary'} block>${formattedAmount}</Button>
                : <Button className="mt-1" variant="primary" block>
                    ${formattedAmount}
                    <span className="remove" onClick={handleDeleteClick}><SvgIcon icon="x-circle" /></span>
                  </Button>
            }
        </li>
    )
}

const TipList = ({ items = [], onEditClick, editable, handleAddAmountClick, secondary, onAmountDelete }) => {

    return (
        <>
            <SectionHeader 
                isBtn={onEditClick} 
                onBtnClick={onEditClick} 
                icon="edit-pencil"
                title="Tip Amounts" />
            <ul className="list-unstyled px-5">
                { 
                    items.length > 0 
                    ? items.map((item) => <ListItem 
                                            editable={editable} 
                                            key={item.toString()} 
                                            secondary={secondary} 
                                            onDelete={onAmountDelete}
                                            amount={item}></ListItem>) 
                    : <Empty></Empty>
                }
                {editable && <li>
                                <Button className="mt-1 " variant="info" block onClick={handleAddAmountClick}>
                                    <span className="icon"><SvgIcon size="24" icon="plus-circle"/></span> Add Amount
                                </Button>
                            </li>}
            </ul>    
        </>
    );
}

export default TipList;