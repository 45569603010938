import React, { useRef, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import downloadPNG from '../../../utils/downloadPNG';
import * as QRCode from 'easyqrcodejs';
import { jsPDF } from "jspdf";
import SectionHeader from '../../SectionHeader';
import QRToPNG from '../../../utils/QRToPNG';

const QR_PNG_SIZE = 1025;

export default function QRGenerator(props){
    const qrRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const generatePNG = () => {
        return QRToPNG(props.url, 1024)
    }   

    const downloadAsPNG = () => {
        setIsLoading(true);
        generatePNG().then(data => {
                downloadPNG(data);
                setIsLoading(false);
        })
    }

    const generatePDF = () => {
        const doc = new jsPDF({
            unit: 'mm',
            format: 'letter'
        });
        setIsLoading(true);
        generatePNG().then(dataUrl => {
            let width = doc.internal.pageSize.getWidth();
            let height = doc.internal.pageSize.getHeight();
            let imgWidth = 200;
            let imgHeight = 150;

            doc.addImage(dataUrl, 'JPEG', (width / 2) - imgWidth / 2, (height / 2) - imgHeight / 2, imgWidth, imgHeight);
            doc.save("toss-a-coin-qr.pdf");
            setIsLoading(false);
        })

    }

    const generateQR = () => {
        if(qrRef.current){
            let options = {
                text: props.url,
                width: QR_PNG_SIZE,
                height: QR_PNG_SIZE
    
            }
            qrRef.current.innerHTML = '';
            const container = qrRef.current;
         
            new QRCode(container, options);
        }

    }

    useEffect(() => {
        generateQR();
    }, [props.url])

    const qrClassName = `qr-code ${props.hideBg ? 'qr-code-hidebg' : ''}`
    const btnClassName = `d-flex justify-content-center align-items-center mt-4 ${props.btnColumn ? 'flex-column' : ''}`;
    const btnVariant = props.btnVariant ? props.btnVariant : 'primary';

    return (
        <div>
            {!props.hideHeader && <SectionHeader title="My QR Code" />}
            {props.placeholder 
                ? <i>Please configure your account username before using QR</i>
                :
                    <>
                        <div className={qrClassName}>
                            <div ref={qrRef} className="d-flex justify-content-center align-items-center qr-code__content"></div>
                        </div>
                        <div className={btnClassName}>
                            <Button block variant={btnVariant} className="mx-1 mb-2" onClick={downloadAsPNG} disabled={isLoading}>
                                Generate PNG <br/> <small>Image for sharing</small>
                            </Button>
                            <Button block variant={btnVariant} className="mx-1 my-0 mb-2" onClick={generatePDF} disabled={isLoading}>Generate PDF <br/> <small>For Printing</small></Button>
                        </div>
                    </>
            }
        </div>
    );
}