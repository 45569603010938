import http from '../http';

const api = http(process.env.REACT_APP_API_URL);

export default {
    user: {
        iam(){
            return api.get('/iam');
        },
        update(params){
            return api.put('/user', params)
        },

        show(username){
            return api.get(`/user/${username}`)
        },
    },
    payment: {
        store(user, params){
            return api.post(`/pay/${user}`, params)
        }
    },
    account: {
        fetch(params){
            return api.get('/account', {params})
        },
        store(params){
            return api.post(`/account`, params)
        },
    }
}