import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { iam, setFormField, updateProfile, uploadAvatar } from '../../store/actions/profile';
import { Form, Container, Row, Col, Button, Spinner } from "react-bootstrap"
import Layout from "../../components/common/Layout"
import Info from '../../components/profile/Info'
import TipList from '../../components/profile/TipList';
import ImageUploadModal from '../../components/profile/ImageUploadModal';
import AddAmountModal from '../../components/profile/AddAmountModal';
import _ from 'lodash';
import { routes } from '../../router';
import { putMessage } from '../../store/actions/messages';

const ProfileEdit = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showImageModal, setShowImageModal] = useState(false);
    const [showAmountModal, setShowAmountModal] = useState(false);
    const [updated, setUpdated] = useState(false);

    let user = useSelector(state => state.profile.form);
    const isLoading = useSelector(state => state.profile.is_loading);
    const isFirstLogin = useSelector(state => state.auth.is_first_login);

    useEffect(() => {
        dispatch(iam());
        if(updated === true){
            if(isFirstLogin){
                history.push(routes.profile_stripe)
            } else {
                history.push(routes.profile)
            }
            
        }
    }, [updated])

    const handleImageModalClose = () => setShowImageModal(false);
    const handleImageModalOpen = () => setShowImageModal(true);
    const handleAmountModalClose = () => setShowAmountModal(false);
    const handleAmountModalOpen = () => setShowAmountModal(true);
    const addNewAmount = (amount) => {
        let tips = [...user.tips];
        tips.push(parseInt(amount));
        dispatch(setFormField('tips', tips));
        handleAmountModalClose();

    }
    const submit = () => {
        dispatch(updateProfile()).then(result => {
            if(result && result.status === 'success'){
                setUpdated(true);
            }
        })
    }
    const handleImageLoad = (avatar, dataUrl) => {
        dispatch(uploadAvatar(avatar, dataUrl));
        setShowImageModal(false)
    }
    const onAmountDelete = (amount) => {
        let tips = [...user.tips];
        _.remove(tips, (tipAmout) => tipAmout === amount);
        dispatch(setFormField('tips', tips));
    }

    return (
        <Layout 
            title="Edit profile" 
            align="center"
            handleLeftClick={ () => history.goBack()}>
            <Container>
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <Info 
                            user={user} 
                            editable={true} 
                            details={false} 
                            onImageEditClick={handleImageModalOpen} 
                            hideName />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="6" className="text-left">
                        <Form>
                            <Form.Group>
                                <Form.Label>Your name:</Form.Label>
                                <Form.Control value={user.username} onChange={(e) => dispatch(setFormField('username', e.target.value))} type="text"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Add description:</Form.Label>
                                <Form.Control value={user.description} onChange={(e) => dispatch(setFormField('description', e.target.value))} as="textarea" rows="4"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <TipList
                                    items={user.tips}
                                    editable={true} 
                                    secondary
                                    onAmountDelete={onAmountDelete}
                                    handleAddAmountClick={handleAmountModalOpen}
                                ></TipList>
                            </Form.Group>
                        </Form>
                    </Col>

                </Row>
                <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                        <Button disabled={isLoading} block onClick={submit} className="mt-2">
                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit' }
                        </Button>
                    </Col>
                </Row>
            </Container>
            <ImageUploadModal handleImageLoad={handleImageLoad} show={showImageModal} handleClose={handleImageModalClose}/>
            <AddAmountModal show={showAmountModal} handleClose={handleAmountModalClose} handleAddAmount={addNewAmount}/>
        </Layout>
    )
}

export default ProfileEdit;