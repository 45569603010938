import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AuthForm from '../../components/auth/AuthForm/AuthForm';
import firebase from '../../services/firebase';
import { initCurrentUser, clear } from '../../store/actions/auth';
import { useHistory } from "react-router-dom";
import { routes } from '../../router';
import Layout from '../../components/common/Layout';
import { Col, Container, Row, Button } from 'react-bootstrap';

export default function SignIn(){
    const dispatch = useDispatch();
    const history = useHistory();

    const is_authenticated = useSelector(state => state.auth.is_authenticated);
    const is_first_login = useSelector(state => state.auth.is_first_login);


    useEffect(() => {
        if(is_authenticated){
            if(is_first_login) {
                history.replace(routes.profile_edit)
            } else {
                history.replace(routes.profile)
            }
        }
    })

    return (
        <Layout 
            // title="Sign in or register"
            align="center"
            hideLeftBtn
            >
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <AuthForm /> 
                        </Col>
                    </Row>
                </Container>
        </Layout>
    )
}