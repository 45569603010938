import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import config from './config';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import RTDB from './RTDB';
import context from "react-bootstrap/esm/AccordionContext";

export default {
    init(){
        if(firebase.apps.length === 0){
            firebase.initializeApp(config);
            
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                firebase.auth().useEmulator("http://localhost:9099");
                firebase.database().useEmulator("localhost", 9000);
            }

            this.initRecaptcha()
        }
    },
    initAuthUI(){
        const instance = firebaseui.auth.AuthUI.getInstance();
        if(instance){
            return instance;
        } else {
            return new firebaseui.auth.AuthUI(firebase.auth());
        }

    },
    storage() {
        return firebase.storage();
    },
    storageUsersRef(){
        return firebase.storage().ref().child('users');
    },
    storageUsersUpload(path, file){
        const usersRef = this.storageUsersRef();
        const user = usersRef.child(path);

        return user.put(file)
    },
    database(){
        let db = firebase.database();
    
        return db;
    },
    initRecaptcha(){
        if(!window.recaptchaVerifier){
            const container = document.createElement('div');
            container.style.position = 'absolute';
            container.style.left = '100%';
            container.style.top = '0';
            container.style.transform = 'translateX(-100%)';
    
            container.setAttribute('id','recaptcha-container');
            document.body.appendChild(container);
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
            });
        }
    },
    removeRecaptcha(){
        const container = document.querySelector('#recaptcha-container');
        container.style.display = 'none';
    },

    instance: firebase
};

export const Database = RTDB;