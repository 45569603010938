import action_types from '../actionTypes';
import firebase, { Database } from '../../services/firebase';
import store from '../index';
import { get } from 'lodash';
import moment from 'moment';

function formatListData(data){
    let result = [];

    data.forEach(item => {
        result.push({
            title: moment(item.created_at).format('MM, dd | HH:mm'),
            value: item.amount
        })
    })

    return result;
}

export function setLoading(flag){
    return {
        type: action_types.PAYMENT_HISTORY_SET_LOADING,
        flag
    }
}

export function setData(data){
    return {
        type: action_types.PAYMENT_HISTORY_SET_DATA,
        data
    }
}

export function setListType(listType){
    return {
        type: action_types.PAYMENT_HISTORY_SET_TYPE,
        listType
    }
}

export function fetch(){
    return dispatch => {
        const pagination = get(store.getState(), 'payment_history.pagination', {});
        const currentData = get(store.getState(), 'payment_history.data', []);
        const listType = get(store.getState(), 'payment_history.type', null);
        const userUid = get(store.getState(), 'profile.uid', null);

        dispatch(setLoading(true));

        const db = new Database(firebase.database(), pagination);
        let ref = listType === 'summary' ? `user_summary/${userUid}` : `user_income/${userUid}`;
    
        db.setRef(ref);

        db.fetch().then(result => {
            let payload = {
                data: [...currentData, ...result.data],
                pagination: result.pagination,
                next: result.data.length > 0
            }
            dispatch(setData(payload));
            dispatch(setLoading(false));
        }).catch(e => {
            dispatch(setLoading(false));
            console.log('error is ', e);
        })
    }
}