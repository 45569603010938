import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ImageUploader from '../../ImageUploader';

export default ({ show, handleClose, handleImageLoad}) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Add your photo</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="text-center mb-2">How would you like to add photo?</div>
                        <ImageUploader handleImageLoad={handleImageLoad} capture>Take Photo From Camera</ImageUploader>
                        <ImageUploader handleImageLoad={handleImageLoad}>Upload photo from phone</ImageUploader>
                    </Modal.Body>
            </Modal>
        </div>
    )
}