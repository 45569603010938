import action_types from '../actionTypes';
import { cloneDeep } from 'lodash';

const initial_state = {
    is_loading: false,
    type: 'summary',
    data: [],
    next: true,
    pagination: {
        limit: 12,
        last_key: null
    }
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.PAYMENT_HISTORY_SET_LOADING: {
            return { ...state, is_loading: action.flag }
        }
        case action_types.PAYMENT_HISTORY_SET_DATA: {
            return { ...state, ...action.data}
        }
        case action_types.PAYMENT_HISTORY_SET_TYPE: {
            return { ...state, type: action.listType, pagination: {
                limit: 5,
                last_key: null
            }}
        }
        default:
            return state;
    }
}