import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {clear, fetch} from '../../store/actions/user';
import TipSelector from '../../components/tips/TipSelector/TipSelector';
import {attempt, pay} from '../../store/actions/payment';
import {useHistory} from "react-router-dom";
import {routes} from '../../router';
import Layout from '../../components/common/Layout';
import {Container, Row, Col, Spinner} from "react-bootstrap";
import Info from '../../components/profile/Info';
import {Button} from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function Tip() {
    const {qr} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedAmount, setSelectedAmount] = useState();
    const tips = useSelector(state => state.user.tips);
    const usernameFetched = useSelector(state => state.user.username);
    const uid = useSelector(state => state.user.uid);
    const avatar = useSelector(state => state.user.avatar);
    const description = useSelector(state => state.user.description);
    const noUserFound = useSelector(state => state.user.no_user_found);
    const userIsLoading = useSelector(state => state.user.is_loading);
    const isLoading = useSelector(state => state.payment.is_loading);

    useEffect(() => {
        dispatch(fetch(qr));
        return () => {
            dispatch(clear())
        }
    }, [qr])

    const handleCheckoutStripe = async (sessionId) => {
        const stripe = await stripePromise;

        const result = await stripe.redirectToCheckout({
            sessionId: sessionId,
        });

        if (result.error) {
            console.log('error', result.error);
        }
    }

    const payTip = (tip) => {
        dispatch(attempt({
            amount: (tip * 100),
            username: usernameFetched,
            uid: uid
        }))
        dispatch(pay()).then((result) => {
            if (result && result.status == 'success') {
                //history.push(routes.payment)
                if (result.session_id) {
                    handleCheckoutStripe(result.session_id);
                }
            }
            //history.push(routes.payment)
        })
    }
    const handleTipClick = (tip) => {
        setSelectedAmount(tip)
    }

    let user = {
        username: usernameFetched,
        avatar,
        description
    }

    return (
        <Layout align="center">
            <main className="d-flex align-items-center">
                <Container>
                    {!noUserFound
                        ? <>
                            <Row className="justify-content-center">
                                <Col md="6" className="text-center">
                                    <Info user={user} editable={false} isLoading={userIsLoading}/>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="justify-content-center">
                                <Col md="4" className="text-center">
                                    <TipSelector
                                        selectedAmount={selectedAmount}
                                        tips={tips}
                                        isLoading={userIsLoading}
                                        handleTipClick={amount => handleTipClick(amount)}
                                    >
                                    </TipSelector>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                <Col md="6" className="text-center">
                                    {!userIsLoading &&
                                    <Button
                                        disabled={!selectedAmount || isLoading}
                                        block
                                        onClick={() => payTip(selectedAmount)}>
                                        {isLoading ? <Spinner animation="border" size="sm"/> : 'Submit'}
                                    </Button>}
                                </Col>
                            </Row>
                        </>
                        : <Row className="justify-content-center">
                            <Col md="6" className="text-center py-5">
                                <h2>No user found with qr code <i>{qr}</i></h2>
                            </Col>
                        </Row>}

                </Container>
            </main>
        </Layout>
    )
}