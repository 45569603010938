import { cloneDeep } from 'lodash';

export default class RTDB {
    
    pagination = {
        limit: 2,
        last_key: null,
    };

    constructor(database, pagination){
        this.database = database;

        if(!!pagination){
            this.pagination = { ...pagination };
        }
    }

    setRef(ref){
        this.ref = this.database.ref(ref);
    }

    //transform result object to array
    _formatData(data){
        let result = [];

        for (const key in data) {
            let obj = cloneDeep(data[key]);
            obj.key = key;
            result.push(obj)
        }

        return result;
    }

    fetch(){
        const { last_key, limit } = this.pagination;
        let ref = this.ref;
        
        if(last_key){
            ref = ref.orderByKey().startAt(last_key).limitToFirst(limit + 1)
        } else {
            ref = ref.orderByKey().limitToFirst(limit);
        }


        return ref.get().then(snapshot => {
            let data = null;
            let result = {
                pagination: {
                    limit,
                    last_key
                }
            };

            if (snapshot.exists()) {
                data =  this._formatData(snapshot.val());
                if(last_key){
                    data.shift();
                }
            } else {
                data = []
            }

            if(data.length > 0){
                result.pagination.last_key = data[data.length - 1].key;
            }

            result.data = data;
            return result;
            
        })
    }

}