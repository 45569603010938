import React from 'react';


const StepTitle = ({ title }) => <div className="stepper__title">{title}</div>;

const StepCount = ({ count }) => {
    return (
        <div className="stepper__count">
            <div className="stepper__num">
             {count}
            </div>
        </div>
    )
};

const Step = ({ count, title }) => {
    return (
        <div className="stepper__item">
            <StepCount count={count}></StepCount>
            <StepTitle title={title}></StepTitle>
        </div>
    )
}

export default ({items}) => {
    return (
        <div className="stepper">
            {items.map((item, index) => <Step count={index + 1} key={index} title={item}></Step>)}
        </div>
    )
}


