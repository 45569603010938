import action_types from '../actionTypes';
import { cloneDeep } from 'lodash';

const auth_token = JSON.parse(localStorage.getItem('toc_auth_token'));

const initial_state = {
    is_loading: false,
    is_authenticated: !!auth_token,
    token: auth_token,
    emailSent: false,
    codeSent: false,
    is_first_login: false,
    form: {
        login: '',
        type: null,
        confirmation_code: ''
    },
    form_error: null,
    need_email: false 
}

export default function(state = initial_state, action) {
    switch (action.type) {
        case action_types.AUTH_SET_LOADING: {
            return { ...state, is_loading: action.flag }
        }
        case action_types.AUTH_CLEAR: {
            return { ...initial_state, token: null, is_authenticated: false}
        }
        case action_types.AUTH_SET_FORM_FIELD: {
            let form = cloneDeep(state.form);
            form[action.key] = action.value;
            return { ...state, form}
        }
        case action_types.AUTH_SET_EMAIL_SENT: {
            return { ...state, emailSent: true }
        }
        case action_types.AUTH_SET_CODE_SENT: {
            return { ...state, codeSent: true }
        }
        case action_types.AUTH_SET_NEED_EMAIL: {
            return { ...state, need_email: action.flag }
        }
        case action_types.AUTH_SET_DATA: {
            return { ...state, ...action.data}
        }
        case action_types.AUTH_SET_FORM_ERROR: {
            return { ...state,  form_error: action.error }
        }
        default:
            return state;
    }
}