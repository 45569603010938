import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {uploadAvatar } from '../../store/actions/user';
import Link from '../../components/Link';
import { Input } from '../../components/common';
import { Button, Col, Container, Row, Image} from 'react-bootstrap';
import ImageUploader from '../../components/ImageUploader';
import {logout } from "../../store/actions/auth";
import { putMessage } from "../../store/actions/messages";
import Layout from "../../components/common/Layout";
import Logo from "../../components/Logo";
import ContentSection from "../../components/home/ContentSection";
import Stepper from "../../components/home/Stepper";
import optionsImage from '../../assets/images/home/options.png';
import applePayImage from '../../assets/images/home/apple-pay.png';
import googlePayImage from '../../assets/images/home/google-pay.png';
import opportunitiesImage from '../../assets/images/home/opportunities.png';
import FeeBlock from "../../components/home/FeeBlock";

const STEPPER_ITEMS = [
    'Create an account using your email or phone number ', 
    'Fill out some simple details about yourself for your profile ',
    'Upload a photo of yourself (optional)',
    'Print out your unique QR code and start getting paid! '
    ];

export default function Home() {

    return (
        <Layout hideLeftBtn>
        <div className="home px-0 pb-4">
            <section className="text-center py-5" id="hero">
            <Row className="justify-content-center m-0">
                <Col md="4">
                    <div className="img-bg">
                        <Logo size={160} />
                    </div>
                    <h1 className="mt-3">tossacoin.me</h1>
                    <p className="mb-4">The fastest and easiest way to collect <br />digital tips in any setting! </p>
                    <div className="px-5">
                        <Link to={{name: 'signin'}}>
                            <Button block as="span">Sign Up</Button>
                        </Link>
                    </div>
                    <Link to={{name: 'signin'}}>
                        <Button variant="link" className="font-weight-normal" as="span">Already have an account</Button>
                    </Link>
                    
                </Col>
            </Row>
            </section>

            <ContentSection title="How to get payed" id="how-to-get-payed">
                <Stepper items={STEPPER_ITEMS}></Stepper>
            </ContentSection>

            <ContentSection title="The most simple option to get payed" id="payment-options" topGradient={true} bottomGradient={true}>
                <div className="img-bg">
                    
                    <Image src={optionsImage} size={160}></Image>
                    <div className="img-bg-light"></div>
                </div>
                <p className="py-4">No signup required to send a tip! No username, no password, just toss a coin!</p>
                <div className="d-flex justify-content-center mb-4">
                    <Image className="mx-2" src={applePayImage} size={120}></Image>
                    <Image className="mx-2" src={googlePayImage} size={120}></Image>
                </div>
                <p className="mb-5">Supports both Apple Pay and Google Pay for the fastest tipping on the web! </p>

                <h2>The most opportunities to get payed</h2>
                <div className="img-bg">
                    <Image src={opportunitiesImage} size={160}></Image>
                    <div className="img-bg-light"></div>
                </div>
                <p className="py-4">You can still get tipped even if someone films your performance and posts it online </p>

            </ContentSection>
            
            {/* <ContentSection title="" id="opportunities">
      <div className="img-bg">
                    
                    <Image src={opportunitiesImage} size={160}></Image>
                    <div className="img-bg-light"></div>
                </div>
                <p className="py-4">You can still get tipped even if someone films your performance and posts it online </p>
            
            </ContentSection> */}

            <ContentSection title="Super low transaction fees" id="fees" topGradient bottomGradient> 
                <FeeBlock title="The Tippers pays" subtitle="the CC transaction fee: " blockText="2.9% + 30¢"></FeeBlock>
                <FeeBlock title="You pay" subtitle="a small platform fee: " blockText={"5%\xA0capped\xA0at\n$50/month"} ></FeeBlock>
                <div className="px-5 mt-5">
                    <Link to={{name: 'signin'}}>
                        <Button block as="span">Sign Up</Button>
                    </Link>
                </div>
                <Link to={{name: 'signin'}}>
                    <Button variant="link" as="span" className="font-weight-normal">Already have an account</Button>
                </Link>
            </ContentSection>
        </div>
        </Layout>
    )
}