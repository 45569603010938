import api from '../../api';
import action_types from '../actionTypes';
import store from '../index';
import { get } from 'lodash';

export function setLoading(flag) {
    return {
        type: action_types.PAYMENT_SET_LOADING,
        flag
    }
}

export function setFormField(key, value) {
    return {
        type: action_types.PAYMENT_SET_FORM_FIELD,
        key,
        value
    }
}

export function attempt({amount = 0, username = null, uid = null}) {
    return {
        type: action_types.PAYMENT_ATTEMPT,
        value: {amount, username, uid}
    }
}

export function confirm() {
    return {
        type: action_types.PAYMENT_CONFIRM,
    }
}

export function extra({amount = 0}) {
    return {
        type: action_types.PAYMENT_ADD_EXTRA,
        value: {amount}
    }
}

export function checkout() {
    return {
        type: action_types.PAYMENT_CHECKOUT,
        value: {}
    }
}

export function setStep(value) {

    return {
        type: action_types.PAYMENT_SET_STEP,
        value
    }
}
export function setStripeData(data){
    return {
        type: action_types.PAYMENT_SET_STRIPE_DATA,
        data
    }
}

export function pay() {
    return dispatch => {
        dispatch(setLoading(true));
        const state = store.getState();
        const user = get(state, 'payment.uid', null);
        const amount = get(state, 'payment.amount', null);

        return api.payment.store(user, { amount })
            .then(result => {
                dispatch(setLoading(false))
                
                dispatch(setStripeData({
                    client_secret: result.data.client_secret,
                    session_id: result.data.session_id,
                    account_id: result.data.account_id,
                }))

                return { status: 'success', session_id: result.data.session_id }
                // window.localStorage.setItem('tossacoin_payment_user', user) 
                // window.localStorage.setItem('tossacoin_payment_amount', amount)
            })
            .catch(e => {
                console.log('e', e);
                dispatch(setLoading(false))
                // window.localStorage.removeItem('tossacoin_payment_user') 
                // window.localStorage.removeItem('tossacoin_payment_amount')
            })
    }
} 