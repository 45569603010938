import React from 'react';
import {Row, Col} from 'react-bootstrap';

export default ({ id, title, children, topGradient = false, bottomGradient = false }) => {
    return (
        <section className={`text-center content-section ${topGradient  || bottomGradient ? 'content-section-bg' : ''}`} id={id}>
            <Row className="content-section__content justify-content-center m-0">
                <Col md={4}>
                    <h2>{title}</h2>
                    {children}
                </Col>
            </Row>
            {topGradient && <div className="content-section__topgradient"></div>}
            {bottomGradient && <div className="content-section__bottomgradient"></div>}
        </section>
    );
}