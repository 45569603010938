'use strict';

import _ from 'lodash';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import middlewares from './middlewares';
import objectToFormData from 'object-to-formdata';

export default function(baseUrl) {
  const client = axios.create({
    baseURL: baseUrl
  });

  axiosCancel(client, {
    debug: false
  });



  _.each(middlewares, middleware => {
    let type = middleware.type;
    let fulfilled = _.get(middleware, 'handler', r => r);
    let rejected = _.get(middleware, 'error', r => r);

    fulfilled.bind({ axios: client });
    rejected.bind({ axios: client });

    client.interceptors[type].handlers.push({ fulfilled, rejected });
  });

  const convertToFormdata = function(json_data) {
    return json_data;
    //return objectToFormData(json_data, { indices: true });
  };

  const wrapper = {
    get: (url, params = null) => {
      return client.get(url, params);
    },
    post: (url, payload = null) => {
      return client.post(url, convertToFormdata(payload));
    },
    put: (url, payload = null) => {
      return client.put(url, convertToFormdata(payload));
    },
    patch: (url, payload = null) => {
      return client.patch(url, convertToFormdata(payload));
    },
    delete: (url, payload = null) => {
      return client.delete(url, convertToFormdata(payload));
    }
  };
  return wrapper;
}
