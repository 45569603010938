import _ from 'lodash';
import store from '../../store';

export default {
  type: 'request',
  handler: config => {
    const token = _.get(store.getState(), 'auth.token', null);

    if(token){
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
  }
};
