import action_types from '../actionTypes';
import randomString from '../../utils/randomString';
import _ from 'lodash';
import store from '../index'

export function putData(message) {
    return {
        type: action_types.MESSAGE_PUT_DATA,
        message
    }
}

export function clear() {
    return {
        type: action_types.MESSAGE_CLEAR,
        
    }
}

export function remove(id) {
    return {
        type: action_types.MESSAGE_REMOVE,
        id
    }
}

export function putMessage(body, variant = 'info', timeout = 3000) {
    return dispatch => {
        const messages = _.get(store.getState(), 'messages.messages', []);
        const id = randomString(10);
        const message = {
            body,
            variant,
            id
        }

        const sameMessage = messages.find(item => item.body === body);
        if(!sameMessage){
            dispatch(putData(message));
            setTimeout(() => dispatch(remove(id)), timeout)
        }

    }
}