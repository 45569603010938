import React from 'react';


export default ({ title, subtitle, blockText}) => {
    return (
        <div className="fee-block">
            <h3 className="fee-block__title">{title}</h3>
            <p className="fee-block__subtitle">{subtitle}</p>
            <div className="fee-block__content">
                {blockText}
            </div>
        </div>
    )
}