import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/common/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import { iam } from '../../store/actions/profile';
import QRGenerator from '../../components/profile/QRGenerator';
import { useHistory } from 'react-router';
import { routes } from '../../router';
import genereateQrUrl from "../../utils/generateQrUrl";

export default function GenerateQR(){
    const dispatch = useDispatch();
    const history = useHistory();

    const username = useSelector(state => state.profile.username);
    const qr = useSelector(state => state.profile.qr);
    const userUrl = genereateQrUrl(qr);

    useEffect(() => {
        dispatch(iam());
    }, [])

    


    return (
        <Layout title="Generate QR Code" align="center" fill  handleLeftClick={() => history.replace(routes.profile)}>
            <Container className="align-self-stretch d-flex flex-column generate-qr">
                <Row className="justify-content-center my-auto">
                    <Col md="6" className="text-center px-5">
                        <QRGenerator btnColumn btnVariant={'info'} hideHeader hideBg url={userUrl} placeholder={!username} />   
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}