import api from '../../api';
import action_types from '../actionTypes';

export function setData(data){
    return {
        type: action_types.USER_SET_DATA,
        data
    }
}

export function setLoading(flag){
    return {
        type: action_types.USER_SET_LOADING,
        flag
    }
}

export function setUserNotFound(flag){
    return {
        type: action_types.USER_SET_NOT_FOUND,
        flag
    }
}

export function clear(){
    return {
        type: action_types.USER_CLEAR
    }
}

export function fetch(username){
    return dispatch => {
        dispatch(setLoading(true));
        return api.user.show(username).then(res => {
            dispatch(setData(res.data))
            dispatch(setLoading(false));
        }).catch(error => {
            if(error.response.status === 404){
                dispatch(setUserNotFound(true))
            }
            dispatch(setLoading(false));
        })
    }
}

