import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';


export default ({ show, handleClose, handleAddAmount}) => {
    const [value, setValue] = useState(0);

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size="sm" dialogClassName="add-amount">
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">New Amount</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Control type="number" onChange={(e) => setValue(e.target.value)} value={value} placeholder="Enter new Amount"></Form.Control>
                        </Form.Group>
                        <Button block onClick={() => handleAddAmount(value)}>Submit</Button>
                    </Modal.Body>
            </Modal>
        </div>
    )
}