import domtoimage from 'dom-to-image';
import * as QRCode from 'easyqrcodejs';

export default function QRToPNG(text, size = 1024){
    const options = {
        text,
        width: size ,
        height: size ,
    }

    const container = document.createElement('div');
    const qrCode = document.createElement('div');
    const qrCodeContent = document.createElement('div');

    qrCodeContent.classList.add('d-flex', 'justify-content-center', 'align-items-center', 'qr-code__content');
    qrCode.classList.add('qr-code');

    qrCode.appendChild(qrCodeContent);
    container.appendChild(qrCode);

    container.style.position = 'relative';
    container.style.zIndex = '-1';
    container.style.overflow = 'hidden';
    container.style.width = '0px';
    container.style.height = '0px';
    
    qrCode.style.width = `${size}px`;
    qrCode.style.position = 'absolute';

    document.body.appendChild(container)

    new QRCode(qrCodeContent, options);

   return domtoimage.toPng(qrCode)
        .then(function (dataUrl) {
            container.remove();
            return dataUrl;
        })
        .catch(function (error) {
            console.error('PNG render Error', error);
        });

}